import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import KeziaLogo from '@core/layouts/components/Logo.vue'

import ResponseAlert from '@/layouts/components/ResponseAlert.vue'
import CustomButton from '@/layouts/components/CustomButton.vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('kezia-logo', KeziaLogo)
Vue.component('ResponseAlert', ResponseAlert)
Vue.component('CustomButton', CustomButton)
