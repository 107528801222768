<template>
  <transition name="fade">
    <div
      v-if="$store.state.app.spinner"
      class="loader"
    >
      <div class="lds-ring">
        <div /><div /><div /><div />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SpinnerComponent',
}
</script>

<style lang="scss" scoped>
  .loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    animation: lds-ring 1s infinite;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 6px solid #B22A2E;
    border-radius: 50%;
    animation: lds-ring-data-v-06859f7c 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #B22A2E transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

</style>
