export default [
  // ///////////////////////////////////////////////////////////
  // Auth
  // ///////////////////////////////////////////////////////////
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      link: 'profile',
      pageTitle: 'Edit Profil',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Edit Profile',
          active: true,
        },
      ],
    },
  },

  {
    path: '/profile/banks/add',
    name: 'profile-bank-add',
    component: () => import('@/views/profile/bank/form.vue'),
    meta: {
      link: 'profile',
      pageTitle: 'Tambah Akun Bank',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Akun Bank',
          to: '/profile',
        },
        {
          text: 'Tambah Akun Bank',
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile/banks/edit/:id',
    name: 'profile-bank-edit',
    component: () => import('@/views/profile/bank/form.vue'),
    meta: {
      link: 'profile',
      pageTitle: 'Update Akun Bank',
      breadcrumb: [
        {
          text: 'Profile',
        },
        {
          text: 'Akun Bank',
          to: '/profile',
        },
        {
          text: 'Update Akun Bank',
          active: true,
        },
      ],
    },
  },
  {
    path: '/edit-password',
    name: 'edit-password',
    component: () => import('@/views/editPassword.vue'),
    meta: {
      link: 'edit-password',
      pageTitle: 'Edit Password',
      breadcrumb: [
        {
          text: 'Dashboard',
          to: '/',
        },
        {
          text: 'Edit Password',
          active: true,
        },
      ],
    },
  },

  // ///////////////////////////////////////////////////////////
  // Guest
  // ///////////////////////////////////////////////////////////
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
      auth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      auth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
  {
    path: '/error-500',
    name: 'error-500',
    component: () => import('@/views/error/Error500.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
]
