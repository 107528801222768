export default [
  {
    path: '/reporting-so',
    name: 'reporting-so',
    component: () => import('@/views/reporting/sales-order.vue'),
    meta: {
      pageTitle: 'Reporting SO',
      permissions: 'view/reporting-so',
    },
  },
  {
    path: '/reporting-po',
    name: 'reporting-po',
    component: () => import('@/views/reporting/purchase-order.vue'),
    meta: {
      pageTitle: 'Reporting PO',
      permissions: 'view/reporting-po',
    },
  },
]
