<template>
  <b-col
    v-if="message"
    md="12"
    class="mb-10"
  >
    <b-alert
      class="p-1 text-center"
      show
      :variant="variant"
    >
      {{ message }}
    </b-alert>
  </b-col>
</template>
<script>
import {
  BCol, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'ResponseAlert',
  components: {
    BCol,
    BAlert,
  },
  data() {
    return {
      variant: '',
      message: '',
    }
  },
  methods: {
    setResponse(error, variant) {
      const { code, data, message } = error
      if (code === 422) {
        this.message = data.map(({ message }) => message.replace(/"|\//gi, '')).join(', ')
      } else {
        this.message = message
      }
      this.variant = variant
      window.scrollTo(0, 0)
      setTimeout(() => this.message = '', 10000)
    },
  },
}
</script>
