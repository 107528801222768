import Vue from 'vue'
// Rich Text Editor
import VueQuillEditor from 'vue-quill-editor'
// eslint-disable-next-line import/no-unresolved
import { ImageExtend, QuillWatch } from 'quill-image-extend-module'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

const { Quill } = VueQuillEditor
window.Quill = Quill
// eslint-disable-next-line import/no-unresolved
const ImageResize = require('quill-image-resize-module').default

Quill.register('modules/ImageExtend', ImageExtend)
Quill.register('modules/imageResize', ImageResize)

Vue.use(VueQuillEditor, {
  modules: {
    imageResize: {
      modules: ['Resize'],
    },
    ImageExtend: {
      loading: true,
      name: 'file',
      size: 5,
      action: `${process.env.VUE_APP_API}misc/upload`,
      headers: xhr => {
        xhr.setRequestHeader('Accept', 'application/json')
        xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`)
      },
      response: res => res.data.file,
    },
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image() {
          let fileInput = document.querySelector('.quill-image-input')
          if (fileInput === null) {
            fileInput = document.createElement('input')
            fileInput.setAttribute('type', 'file')
            fileInput.setAttribute('accept', 'image/*')
            fileInput.classList.add('quill-image-input')
            fileInput.style.display = 'none'

            fileInput.addEventListener('change', () => {
              const self = QuillWatch.active
              self.file = fileInput.files[0]
              fileInput.value = ''

              if (!self.file.type.includes('image/')) {
                alert('Format file not supported')
                // Vue.notify({ type: 'error', title: 'Error', text: 'Format file not supported'})
                return
              }

              if (self.config.size && self.file.size >= self.config.size * 1024 * 1024 && self.config.loading) {
                alert('Total file size must be lower than 5mb')
                // Vue.notify({ type: 'error', title: 'Error', text: 'Total file size must be lower than 5mb'})
                return
              }
              if (self.config.action) {
                self.uploadImg(self.config.change)
              } else {
                self.toBase64()
              }
            })
            document.body.appendChild(fileInput)
          }
          QuillWatch.emit(this.quill.id)
        },
      },
    },
  },
})
