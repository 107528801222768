export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      permissions: [
        'view/card-purchase-order',
        'view/card-mitra',
        'view/dashboard',
        'view/chart-purchase-order',
        'view/chart-sales-order',
        'view/card-invoice',
      ],
    },
  },
]
