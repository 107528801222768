export default [
  // List
  {
    path: '/level-kfs',
    name: 'level-kfs',
    component: () => import('@/views/master-data/level-kfs/index.vue'),
    meta: {
      link: 'level-kfs',
      pageTitle: 'Level KFS',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Level KFS',
        },
      ],
      permissions: 'view/level-kfs',
    },
  },
  {
    path: '/point',
    name: 'point',
    component: () => import('@/views/master-data/point/index.vue'),
    meta: {
      link: 'point',
      pageTitle: 'POINT',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Point',
        },
      ],
      permissions: 'view/point',
    },
  },
  // {
  //   path: '/kategori-produk',
  //   name: 'kategori-produk',
  //   component: () => import('@/views/master-data/product-categories/index.vue'),
  //   meta: {
  //     link: 'kategori-produk',
  //     pageTitle: 'KATEGORI PRODUK',
  //     breadcrumb: [
  //       {
  //         text: 'Master Data',
  //       },
  //       {
  //         text: 'Kategori Produk',
  //       },
  //     ],
  //     permissions: 'view/kategori-produk',
  //   },
  // },

  // Create
  {
    path: '/level-kfs/add',
    name: 'add-level-kfs',
    component: () => import('@/views/master-data/level-kfs/form.vue'),
    meta: {
      link: 'level-kfs',
      pageTitle: 'Level KFS',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Level KFS',
          to: '/level-kfs',
        },
        {
          text: 'Add Level KFS',
          active: true,
        },
      ],
      permissions: 'add/level-kfs',
    },
  },
  {
    path: '/point/add',
    name: 'add-point',
    component: () => import('@/views/master-data/point/form.vue'),
    meta: {
      link: 'point',
      pageTitle: 'POINT',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Point',
          to: '/point',
        },
        {
          text: 'Add Point',
          active: true,
        },
      ],
      permissions: 'add/point',
    },
  },
  // {
  //   path: '/kategori-produk/add',
  //   name: 'add-kategori-produk',
  //   component: () => import('@/views/master-data/product-categories/form.vue'),
  //   meta: {
  //     link: 'kategori-produk',
  //     pageTitle: 'KATEGORI PRODUK',
  //     breadcrumb: [
  //       {
  //         text: 'Master Data',
  //       },
  //       {
  //         text: 'Kategori Produk',
  //         to: '/kategori-produk',
  //       },
  //       {
  //         text: 'Tambah Kategori Produk',
  //         active: true,
  //       },
  //     ],
  //     permissions: 'add/kategori-produk',
  //   },
  // },

  // Edit
  {
    path: '/level-kfs/edit/:id',
    name: 'edit-level-kfs',
    component: () => import('@/views/master-data/level-kfs/form.vue'),
    meta: {
      link: 'level-kfs',
      pageTitle: 'Level KFS',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Level KFS',
          to: '/level-kfs',
        },
        {
          text: 'Edit Level KFS',
          active: true,
        },
      ],
      permissions: 'edit/level-kfs',
    },
  },
  {
    path: '/point/edit/:id',
    name: 'edit-point',
    component: () => import('@/views/master-data/point/form.vue'),
    meta: {
      link: 'point',
      pageTitle: 'POINT',
      breadcrumb: [
        {
          text: 'Master Data',
        },
        {
          text: 'Point',
          to: '/point',
        },
        {
          text: 'Edit Point',
          active: true,
        },
      ],
      permissions: 'edit/point',
    },
  },
  // {
  //   path: '/kategori-produk/edit/:id',
  //   name: 'edit-kategori-produk',
  //   component: () => import('@/views/master-data/product-categories/form.vue'),
  //   meta: {
  //     link: 'kategori-produk',
  //     pageTitle: 'KATEGORI PRODUK',
  //     breadcrumb: [
  //       {
  //         text: 'Master Data',
  //       },
  //       {
  //         text: 'Kategori Produk',
  //         to: '/kategori-produk',
  //       },
  //       {
  //         text: 'Edit Kategori Produk',
  //         active: true,
  //       },
  //     ],
  //     permissions: 'edit/kategori-produk',
  //   },
  // },
]
