export default [
  // List
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin-management/admin/index.vue'),
    meta: {
      link: 'admin',
      pageTitle: 'Admin',
      breadcrumb: [
        {
          text: 'Admin Management',
        },
        {
          text: 'Admin',
          active: true,
        },
      ],
      permissions: 'view/admin',
    },
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('@/views/admin-management/role/index.vue'),
    meta: {
      link: 'role',
      pageTitle: 'Role',
      breadcrumb: [
        {
          text: 'Admin Management',
        },
        {
          text: 'Role',
          active: true,
        },
      ],
      permissions: 'view/role',
    },
  },

  // Add
  {
    path: '/admin/add',
    name: 'add-admin',
    component: () => import('@/views/admin-management/admin/form.vue'),
    meta: {
      link: 'admin',
      pageTitle: 'Admin',
      breadcrumb: [
        {
          text: 'Admin Management',
          to: '/admin',
        },
        {
          text: 'Add Admin',
          active: true,
        },
      ],
      permissions: 'add/admin',
    },
  },
  {
    path: '/role/add',
    name: 'add-role',
    component: () => import('@/views/admin-management/role/form.vue'),
    meta: {
      link: 'role',
      pageTitle: 'Role',
      breadcrumb: [
        {
          text: 'Admin Management',
          to: '/role',
        },
        {
          text: 'Add Role',
          active: true,
        },
      ],
      permissions: 'add/role',
    },
  },

  // Edit
  {
    path: '/admin/edit/:id',
    name: 'edit-admin',
    component: () => import('@/views/admin-management/admin/form.vue'),
    meta: {
      link: 'admin',
      pageTitle: 'Admin',
      breadcrumb: [
        {
          text: 'Admin Management',
          to: '/admin',
        },
        {
          text: 'Edit Admin',
          active: true,
        },
      ],
      permissions: 'edit/admin',
    },
  },
  {
    path: '/role/edit/:id',
    name: 'edit-role',
    component: () => import('@/views/admin-management/role/form.vue'),
    meta: {
      link: 'role',
      pageTitle: 'Role',
      breadcrumb: [
        {
          text: 'Admin Management',
          to: '/role',
        },
        {
          text: 'Edit Role',
          active: true,
        },
      ],
      permissions: 'edit/role',
    },
  },

  // Detail
  {
    path: '/admin/:id',
    name: 'detail-admin',
    component: () => import('@/views/admin-management/admin/form.vue'),
    meta: {
      link: 'admin',
      pageTitle: 'Admin',
      breadcrumb: [
        {
          text: 'Admin Management',
          to: '/admin',
        },
        {
          text: 'Detail Admin',
          active: true,
        },
      ],
      permissions: 'detail/admin',
    },
  },
]
