import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    isPageOffline: navigator.onLine,
    windowWidth: 0,
    shallShowOverlay: false,
    perPageOptions: [10, 25, 50, 100],
    spinner: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_PAGE_CONNECTION_STATE(state, val) {
      state.isPageOffline = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_SPINNER(state, boolean) {
      state.spinner = boolean
    },
  },
  actions: {},
}
