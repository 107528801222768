export default [
  // List
  {
    path: '/jasa',
    name: 'jasa',
    component: () => import('@/views/inventory/jasa/index.vue'),
    meta: {
      link: 'jasa',
      pageTitle: 'TREATMENT',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Treatment',
          active: true,
        },
      ],
      permissions: 'view/jasa',
    },
  },
  {
    path: '/jasa/edit/:id',
    name: 'edit-jasa',
    component: () => import('@/views/inventory/jasa/form.vue'),
    meta: {
      link: 'jasa',
      pageTitle: 'TREATMENT',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Treatment',
          to: '/jasa',
        },
        {
          text: 'Edit Treatment',
          active: true,
        },
      ],
      permissions: 'edit/jasa',
    },
  },
  {
    path: '/jasa/:id',
    name: 'detail-jasa',
    component: () => import('@/views/inventory/jasa/form.vue'),
    meta: {
      link: 'jasa',
      pageTitle: 'TREATMENT',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Treatment',
          to: '/jasa',
        },
        {
          text: 'Detail Treatment',
          active: true,
        },
      ],
      permissions: 'view/jasa',
    },
  },
  {
    path: '/produk',
    name: 'produk',
    component: () => import('@/views/inventory/produk/index.vue'),
    meta: {
      link: 'produk',
      pageTitle: 'PRODUK',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Produk',
          active: true,
        },
      ],
      permissions: 'view/produk',
    },
  },
  {
    path: '/produk/edit/:id',
    name: 'edit-produk',
    component: () => import('@/views/inventory/produk/form.vue'),
    meta: {
      link: 'produk',
      pageTitle: 'PRODUK',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Produk',
          to: '/produk',
        },
        {
          text: 'Edit Produk',
          active: true,
        },
      ],
      permissions: 'edit/produk',
    },
  },
  {
    path: '/produk/:id',
    name: 'detail-produk',
    component: () => import('@/views/inventory/produk/form.vue'),
    meta: {
      link: 'produk',
      pageTitle: 'PRODUK',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Produk',
          to: '/produk',
        },
        {
          text: 'Detail Produk',
          active: true,
        },
      ],
      permissions: 'view/produk',
    },
  },
  {
    path: '/bahan-baku',
    name: 'bahan-baku',
    component: () => import('@/views/inventory/bahan-baku/index.vue'),
    meta: {
      link: 'bahan-baku',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Bahan Baku',
          active: true,
        },
      ],
      permissions: 'view/bahan-baku',
    },
  },
  // {
  //   path: '/bahan-baku/edit/:id',
  //   name: 'edit-bahan-baku',
  //   component: () => import('@/views/inventory/bahan-baku/form.vue'),
  //   meta: {
  //     link: 'bahan-baku',
  //     pageTitle: 'Inventory',
  //     breadcrumb: [
  //       {
  //         text: 'Inventory',
  //       },
  //       {
  //         text: 'Bahan Baku',
  //         to: '/bahan-baku',
  //       },
  //       {
  //         text: 'Edit Bahan Baku',
  //         active: true,
  //       },
  //     ],
  //     permissions: 'edit/bahan-baku',
  //   },
  // },
  {
    path: '/bahan-baku/:id',
    name: 'detail-bahan-baku',
    component: () => import('@/views/inventory/bahan-baku/form.vue'),
    meta: {
      link: 'bahan-baku',
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Inventory',
        },
        {
          text: 'Bahan Baku',
          to: '/bahan-baku',
        },
        {
          text: 'Detail Bahan Baku',
          active: true,
        },
      ],
      permissions: 'view/bahan-baku',
    },
  },
]
