/* eslint-disable no-lonely-if */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import api from '@/utils/api'
import axios from '@/libs/axios'

// Routes
import dashboard from './routes/dashboard'
import adminManagement from './routes/admin-management'
import pages from './routes/pages'
import kfs from './routes/kfs'
import masterData from './routes/master-data'
import customerManagement from './routes/customer-management'
import ownerManagement from './routes/owner-management'
import purchaseOrder from './routes/purchase-order'
import promoManagement from './routes/promo-management'
import invoice from './routes/invoice'
import setting from './routes/setting'
import inventory from './routes/inventory'
import reporting from './routes/reporting'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboard,
    ...reporting,
    ...adminManagement,
    ...customerManagement,
    ...ownerManagement,
    ...promoManagement,
    ...purchaseOrder,
    ...kfs,
    ...invoice,
    ...inventory,
    ...masterData,
    ...setting,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach(async (to, from, next) => {
  /* auth middleware */
  const token = localStorage.getItem('token')
  if (to.matched.some(record => record.meta.auth === false)) {
    // check if user visit non auth page
    if (token) next('/')
    else next()
  } else if (to.matched.some(record => record.meta.guest)) {
    // check if user visit guest page (do nothing)
    next()
  } else {
    // Check for auth page
    if (!token) next('/login')
    else {
      if (Object.entries(store.state.user.me).length === 0 || !store.state.user.menus.length) {
        try {
          const response = await Promise.all([
            axios.get(api.me),
            axios.get(api.user_menu),
          ])
          if (response[0].status === 200 && response[1].status === 200) {
            store.dispatch('user/updateUserInfo', response[0].data.message)
            store.dispatch('user/setMenus', response[1].data.data)
          }
        } catch (error) {
          console.log('error', error)
        }
      }

      // Check User Permissions
      if (to.matched.some(record => record.meta.permissions) && store.state.user.permissions.length) {
        const { permissions } = to.meta

        if (typeof permissions === 'string') {
          // if permissions single
          if (store.state.user.permissions.includes(permissions)) {
            next()
          } else {
            next('/')
          }
        } else {
          // if permissions multiple
          const result = permissions.filter(permission => Boolean(store.state.user.permissions.includes(permission)))
          if (result.length > 0) {
            next()
          } else {
            // check if dashboard permission not exist
            if (to.matched.some(record => record.name === 'dashboard')) {
              const route = store.state.user.menus[0].route || store.state.user.menus[0].children[0].route
              next(`/${route}`)
            } else {
              next('/')
            }
          }
        }
      }
      next()
    }
  }
})

export default router
