import firebase from 'firebase/app'
import 'firebase/firebase-messaging'

firebase.initializeApp({
  apiKey: 'AIzaSyD11zKf4emvDXDd2C0eM1_cVUg_oYWEz_M',
  authDomain: 'kezia-skincare.firebaseapp.com',
  projectId: 'kezia-skincare',
  storageBucket: 'kezia-skincare.appspot.com',
  messagingSenderId: '729557066537',
  appId: '1:729557066537:web:97d12174e2257c8e488d38',
  measurementId: 'G-TV4XRF5P0R',
})

navigator.serviceWorker
  .register('firebase-messaging-sw.js')
  .then(registration => {
    const messaging = firebase.messaging()
    messaging.useServiceWorker(registration)
  })
  .catch(err => {
    console.log(err)
  })

// firebase.initializeApp(firebaseConfig)

// export default firebase.messaging()
