<template>
  <router-link
    v-if="type === 'link' && hasPermission(permission)"
    :title="title"
    :to="url"
    :class="className"
  >
    {{ text }}
    <slot />
  </router-link>
  <button
    v-else-if="type === 'button' && hasPermission(permission)"
    :title="title"
    :class="className"
    @click="click"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    className: {
      type: String,
      default: '',
    },
    permission: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'link',
    },
    url: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('click')
    },
  },
}
</script>

<style>

</style>
