import axios from '@/libs/axios'

const notification = {
  get(config) {
    const sortBy = `${config.orderby}.${config.sort}`
    const params = {
      page: config.page,
      limit: config.per_page,
      sortBy,
    }
    return axios.get(config.api, {
      params: Object.assign(params),
    }).then(res => {
      const {
        count,
        rows,
        totalPages,
      } = res.data.data
      config.rows = [...config.rows, ...rows]
      config.total_data = count
      config.total_page = totalPages
    }).catch(error => console.log(error))
  },
  loadMore(config) {
    notification.get(config)
  },
}

export default notification
