import axios from '@/libs/axios'

const table = {
  get(config) {
    const sortBy = `${config.orderby}.${config.sort}`
    const params = {
      page: config.page,
      limit: config.per_page,
      sortBy,
    }
    const additional_params = config.additional_params || {}
    const search = { search: config.search }
    return axios.get(config.api, {
      params: Object.assign(params, search, additional_params),
    }).then(res => {
      const {
        count,
        rows,
        totalPages,
      } = res.data.data
      config.rows = rows
      config.total_data = count
      config.total_page = totalPages

      return res
    }).catch(error => console.log(error))
  },
  sort: (config, orderby) => {
    config.orderby = orderby
    config.sort = config.sort === 'asc' ? 'desc' : 'asc'
    table.get(config)
  },
  gotoPage(config, page) {
    config.page = page
    table.get(config)
  },
}

export default table
