export default {
  me: 'me',
  user_menu: 'me/menu',

  // auth
  login: 'auth/login',
  refresh: 'auth/refresh-token',
  forgotPassword: 'auth/forgot-password',
  resetPassword: 'auth/reset-password',

  // data
  admin: 'admin',
  roles: 'roles',
  listPermissions: 'roles/menu-permissions',
  point: 'point',
  level: 'level',
  customers: 'customers',
  owners: 'owners',
  purchaseOrder: 'purchase-orders',
  purchaseOrders: 'purchase-orders/sales',
  faq: 'faq',
  pageContent: '/page-content',
  promotions: 'promotions',
  product: 'product',
  accuTreatment: 'accu-treatment',
  rawMaterial: 'raw-material',
  bankAccount: 'bank-account',
  invoice: 'invoice',

  // KFS
  kfs: 'kfs',
  kfsEmployee: 'kfs-employee',
  kfsInventory: 'kfs-inventory',
  kfsPromotion: 'kfs-promotion',
  kfsRedeem: 'kfs-redeem',
  treatments: 'treatments',
  kfsHours: 'kfs-hours',
  oldTransaction: 'old-transaction',

  // Dashboard
  changeInvoice: 'dashboard/invoice-change-count',
  saleCount: 'dashboard/sale-count',
  mitraCount: 'dashboard/mitra-count',
  chartSale: 'dashboard/chart-sales',
  notification: 'notification',

  // misc
  list_owner: 'misc/owners',
  list_regency: 'misc/regencies',
  list_kfs: 'misc/kfs',
  list_staff: 'misc/staffs',
  list_treatments: 'misc/treatments',
  list_products: 'misc/products',
  list_bank: 'misc/bank',
  list_rawmaterials: 'misc/raw-materials',
}
