export default [
  // List
  {
    path: '/customer-management',
    name: 'customer-management',
    component: () => import('@/views/customer-management/index.vue'),
    meta: {
      link: 'customer-management',
      pageTitle: 'Customer List',
      breadcrumb: [
        {
          text: 'Customer Management',
          active: true,
        },
      ],
      permissions: 'view/customer',
    },
  },
  {
    path: '/customer-management/add',
    name: 'add-customer',
    component: () => import('@/views/customer-management/form.vue'),
    meta: {
      link: 'customer-management',
      pageTitle: 'Customer',
      breadcrumb: [
        {
          text: 'Customer Management',
          to: '/customer-management',
        },
        {
          text: 'Add Customer',
          active: true,
        },
      ],
      permissions: 'add/customer',
    },
  },
  {
    path: '/customer-management/edit/:id',
    name: 'edit-customer',
    component: () => import('@/views/customer-management/form.vue'),
    meta: {
      link: 'customer-management',
      pageTitle: 'Customer',
      breadcrumb: [
        {
          text: 'Customer Management',
          to: '/customer-management',
        },
        {
          text: 'Edit Customer',
          active: true,
        },
      ],
      permissions: 'edit/customer',
    },
  },
  {
    path: '/customer-management/:id',
    name: 'detail-customer',
    component: () => import('@/views/customer-management/detail/index.vue'),
    meta: {
      link: 'customer-management',
      pageTitle: 'Customer',
      breadcrumb: [
        {
          text: 'Customer Management',
          to: '/customer-management',
        },
        {
          text: 'Detail Customer',
          active: true,
        },
      ],
      permissions: [
        'detail/customer',
        'view/detail-info/customer',
        'view/product/customer',
        'export/product/customer',
        'view/invoice/customer',
        'export/invoice/customer',
        'detail/invoice/customer',
        'view/medical-record/customer',
        'add/medical-record/customer',
        'export/medical-record/cutomer',
        'detail/medical-record/customer',
        'edit/medical-record/customer',
        'export/agenda/customer',
        'view/agenda/customer',
      ],
    },
  },
]
