import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

import axios from '@/libs/axios'
import table from '@/utils/table'
import notification from '@/utils/notification'
import { localize } from 'vee-validate'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-quill-editor'
import '@/libs/vue-google-maps'
import '@/libs/vue-meta'
import '@/libs/moment'
import '@/utils/firebase'

Vue.prototype.$axios = axios
Vue.prototype.$table = table
Vue.prototype.$notification = notification

// Check if user has permission to access some feature
Vue.prototype.hasPermission = permission => store.getters.permissions.includes(permission)
Vue.prototype.formatCurrency = price => `Rp. ${parseFloat(price).toLocaleString().replace(/[.,]/g, m => (m === '.' ? ',' : '.'))}`

localize('id')

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
