export default [
  {
    path: '/accurate-setting',
    name: 'accurate-setting',
    component: () => import('@/views/setting/authentication-accurate/index.vue'),
    meta: {
      link: 'accurate-setting',
      pageTitle: 'Authentication Accurate',
      breadcrumb: [
        {
          text: 'Setting',
        },
        {
          text: 'Authentication Accurate',
          active: true,
        },
      ],
      permissions: 'view/authentication-accurate',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/setting/faq/index.vue'),
    meta: {
      link: 'faq',
      pageTitle: 'FAQ Management',
      breadcrumb: [
        {
          text: 'Setting',
        },
        {
          text: 'FAQ',
          active: true,
        },
      ],
      permissions: 'view/faq',
    },
  },
  {
    path: '/kebijakan-privasi',
    name: 'kebijakan-privasi',
    component: () => import('@/views/setting/kebijakan-privasi/index.vue'),
    meta: {
      link: 'kebijakan-privasi',
      pageTitle: 'Kebijakan Privasi',
      breadcrumb: [
        {
          text: 'Setting',
        },
        {
          text: 'Kebijakan Privasi',
          active: true,
        },
      ],
      permissions: 'view/kebijakan-privasi',
    },
  },
  {
    path: '/syarat-dan-ketentuan',
    name: 'syarat-dan-ketentuan',
    component: () => import('@/views/setting/syarat-dan-ketentuan/index.vue'),
    meta: {
      link: 'syarat-dan-ketentuan',
      pageTitle: 'Syarat dan Ketentuan',
      breadcrumb: [
        {
          text: 'Setting',
        },
        {
          text: 'Syarat dan Ketentuan',
          active: true,
        },
      ],
      permissions: 'view/syarat-dan-ketentuan',
    },
  },

  {
    path: '/faq/add',
    name: 'add-faq',
    component: () => import('@/views/setting/faq/form.vue'),
    meta: {
      link: 'faq',
      pageTitle: 'FAQ Management',
      breadcrumb: [
        {
          text: 'FAQ',
          to: '/faq',
        },
        {
          text: 'Add FAQ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kebijakan-privasi/edit',
    name: 'edit-kebijakan-privasi',
    component: () => import('@/views/setting/kebijakan-privasi/form.vue'),
    meta: {
      link: 'kebijakan-privasi',
      pageTitle: 'Kebijakan Privasi',
      breadcrumb: [
        {
          text: 'Kebijakan Privasi',
          to: '/kebijakan-privasi',
        },
        {
          text: 'Edit Kebijakan Privasi',
          active: true,
        },
      ],
      permissions: 'edit/kebijakan-privasi',
    },
  },
  {
    path: '/syarat-dan-ketentuan/edit',
    name: 'edit-syarat-dan-ketentuan',
    component: () => import('@/views/setting/syarat-dan-ketentuan/form.vue'),
    meta: {
      link: 'syarat-dan-ketentuan',
      pageTitle: 'Syarat dan Ketentuan',
      breadcrumb: [
        {
          text: 'Syarat dan Ketentuan',
          to: '/syarat-dan-ketentuan',
        },
        {
          text: 'Edit Syarat dan Ketentuan',
          active: true,
        },
      ],
      permissions: 'edit/syarat-dan-ketentuan',
    },
  },
  {
    path: '/faq/edit/:id',
    name: 'edit-faq',
    component: () => import('@/views/setting/faq/form.vue'),
    meta: {
      link: 'faq',
      pageTitle: 'FAQ Management',
      breadcrumb: [
        {
          text: 'FAQ',
          to: '/faq',
        },
        {
          text: 'Edit FAQ',
          active: true,
        },
      ],
      permissions: 'edit/faq',
    },
  },
  {
    path: '/faq/:id',
    name: 'detail-faq',
    component: () => import('@/views/setting/faq/form.vue'),
    meta: {
      link: 'faq',
      pageTitle: 'FAQ Management',
      breadcrumb: [
        {
          text: 'FAQ',
          to: '/faq',
        },
        {
          text: 'Detail FAQ',
          active: true,
        },
      ],
    },
  },
]
