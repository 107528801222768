export default [
  {
    path: '/promo',
    name: 'promo',
    component: () => import('@/views/promo-management/index.vue'),
    meta: {
      link: 'promo',
      pageTitle: 'List Promo',
      breadcrumb: [
        {
          text: 'Promo Management',
          to: '/promo',
        },
      ],
      permissions: 'view/promo',
    },
  },
  {
    path: '/promo/add',
    name: 'add-promo',
    component: () => import('@/views/promo-management/form.vue'),
    meta: {
      link: 'promo',
      pageTitle: 'Promo Management',
      breadcrumb: [
        {
          text: 'Promo Management',
          to: '/promo',
        },
        {
          text: 'Add Promo',
          active: true,
        },
      ],
      permissions: 'add/promo',
    },
  },
  {
    path: '/promo/edit/:id',
    name: 'edit-promo',
    component: () => import('@/views/promo-management/form.vue'),
    meta: {
      link: 'promo',
      pageTitle: 'Promo Management',
      breadcrumb: [
        {
          text: 'Promo Management',
          to: '/promo',
        },
        {
          text: 'Edit Promo',
          active: true,
        },
      ],
      permissions: 'edit/promo',
    },
  },
  {
    path: '/promo/:id',
    name: 'detail-promo',
    component: () => import('@/views/promo-management/form.vue'),
    meta: {
      link: 'promo',
      pageTitle: 'KFS',
      breadcrumb: [
        {
          text: 'Promo Management',
          to: '/promo',
        },
        {
          text: 'Detail Promo',
          active: true,
        },
      ],
      permissions: 'detail/promo',
    },
  },
]
