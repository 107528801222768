import axios from 'axios'
import router from '@/router'
import store from '@/store'

const instance = axios.create()

instance.defaults.baseURL = process.env.VUE_APP_API
instance.defaults.headers.get.Accept = 'application/json'
instance.defaults.headers.get['Content-Type'] = 'application/json'

if (localStorage.getItem('token')) {
  instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
}

instance.interceptors.request.use(config => {
  // Do something before request is sent
  if (config.url !== 'misc/upload' || !config.url.includes('notifications')) {
    store.commit('app/UPDATE_SPINNER', true)
  }
  return config
}, error => Promise.reject(error))

instance.interceptors.response.use(response => {
  if (response.config.url !== 'misc/upload') {
    store.commit('app/UPDATE_SPINNER', false)
  }
  return response
}, async error => {
  const originalConfig = error.config;
  if (error.response.config.url !== 'misc/upload') {
    store.commit('app/UPDATE_SPINNER', false)
  }
  if (error.response.status === 401 && !originalConfig._retry) {
    originalConfig._retry = true;

    try {
      const { accessToken } = await store.dispatch('user/refresh')
      return instance({
        ...originalConfig,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .catch(() => {
          store.dispatch('user/logout')
        })
    } catch (_error) {
      store.dispatch('user/logout')
      return Promise.reject(_error)
    }
  }
  if (error.response.status === 500) {
    router.push('/500')
  }
  return Promise.reject(error)
})

export default instance
