export default [
  {
    path: '/purchase-order',
    name: 'purchase-order',
    component: () => import('@/views/purchase-order/index.vue'),
    meta: {
      link: 'purchase-order',
      pageTitle: 'Purchase Order',
      breadcrumb: [
        {
          text: 'Purchase Order',
          to: '/purchase-order',
        },
      ],
      permissions: 'view/purchase-order',
    },
  },
  // {
  //   path: '/purchase-order/edit/:id',
  //   name: 'edit-purchase-order',
  //   component: () => import('@/views/purchase-order/form.vue'),
  //   meta: {
  //     link: 'purchase-order',
  //     pageTitle: 'Purchase Order',
  //     breadcrumb: [
  //       {
  //         text: 'Purchase Order',
  //         to: '/purchase-order',
  //       },
  //       {
  //         text: 'Edit Order',
  //         active: true,
  //       },
  //     ],
  //     permissions: 'edit/purchase-order',
  //   },
  // },
  {
    path: '/purchase-order/:id/add-product',
    name: 'detail-purchase-order-add-product',
    component: () => import('@/views/purchase-order/formProduct.vue'),
    meta: {
      link: 'purchase-order',
      pageTitle: 'Purchase Order',
      breadcrumb: [
        {
          text: 'Purchase Order',
          to: '/purchase-order',
        },
        {
          text: 'Detail Purchase Order',
          goBack: true,
        },
        {
          text: 'Tambah Produk',
          active: true,
        },
      ],
      permissions: ['detail/purchase-order'],
    },
  },
  {
    path: '/purchase-order/:id',
    name: 'detail-purchase-order',
    component: () => import('@/views/purchase-order/form.vue'),
    meta: {
      link: 'purchase-order',
      pageTitle: 'Purchase Order',
      breadcrumb: [
        {
          text: 'Purchase Order',
          to: '/purchase-order',
        },
        {
          text: 'Detail Purchase Order',
          active: true,
        },
      ],
      permissions: 'detail/purchase-order',
    },
  },
]
