import axios from '@/libs/axios'
import router from '@/router'
import api from '@/utils/api'

export default {
  namespaced: true,
  state: {
    me: {},
    menus: [],
    permissions: [],
  },
  getters: {
    // currentBreakPoint: state => {
    //   const { windowWidth } = state
    //   if (windowWidth >= $themeBreakpoints.xl) return 'xl'
    //   if (windowWidth >= $themeBreakpoints.lg) return 'lg'
    //   if (windowWidth >= $themeBreakpoints.md) return 'md'
    //   if (windowWidth >= $themeBreakpoints.sm) return 'sm'
    //   return 'xs'
    // },
  },
  mutations: {
    UPDATE_PAGE_CONNECTION_STATE(state, val) {
      state.isPageOffline = val
    },
    UPDATE_USER_INFO(state, user) {
      state.me = user
    },
    AUTH_USER(state, user) {
      state.me = user
      localStorage.setItem('token', user.accessToken)
      localStorage.setItem('refresh_token', user.refreshToken)
    },
    logoutUser(state) {
      state.me = {}
      state.menus = []
      localStorage.removeItem('token')
      localStorage.removeItem('refresh_token')
      router.push('/login')
    },
    SET_MENUS(state, menus) {
      state.menus = menus
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
  },
  actions: {
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////
    login({ commit }, payload) {
      commit('AUTH_USER', payload)
      axios.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`
      router.push('/')
    },
    async refresh({ commit }) {
      const refreshToken = localStorage.getItem('refresh_token')
      try {
        const { data, status } = await axios.post(api.refresh, { refreshToken })
        if (status === 200) {
          commit('AUTH_USER', data.data)
          axios.defaults.headers.common.Authorization = `Bearer ${data.data.accessToken}`
        }
        return {
          accessToken: data.data.accessToken,
        }
      } catch (error) {
        return new Error('Failed')
      }
    },
    logout({ commit }) {
      delete axios.defaults.headers.common.Authorization
      commit('SET_MENUS', [])
      commit('logoutUser')
    },
    updateUserInfo({ commit }, user) {
      commit('UPDATE_USER_INFO', user)
    },
    setMenus({ commit }, payload) {
      const menus = []
      let permissions = []
      payload.menus.forEach(menu => {
        const data = {
          title: menu.name,
          icon: menu.icon,
        }
        // Set menus
        if (menu.children.length) {
          const childrenMenus = []
          menu.children.forEach(childrenMenu => {
            // check permission for children menu
            if (childrenMenu.permissions.length) {
              permissions = [...permissions, ...childrenMenu.permissions]
            }
            childrenMenus.push({
              title: childrenMenu.name,
              route: childrenMenu.link,
            })
          })
          data.children = childrenMenus
        } else {
          data.route = menu.link
        }

        // Set permissions
        if (menu.permissions.length) {
          permissions = [...permissions, ...menu.permissions]
        }

        menus.push(data)
      })

      commit('SET_MENUS', menus)
      commit('SET_PERMISSIONS', permissions.map(permission => permission.name))
    },
  },
}
