export default [
  {
    path: '/kfs-management',
    name: 'kfs-management',
    component: () => import('@/views/kfs/index.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'List KFS',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
      ],
      permissions: 'view/kfs-management',
    },
  },
  {
    path: '/kfs-management/add',
    name: 'add-kfs',
    component: () => import('@/views/kfs/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Add KFS',
          active: true,
        },
      ],
      permissions: 'add/kfs-management',
    },
  },
  {
    path: '/kfs-management/edit/:id',
    name: 'edit-kfs',
    component: () => import('@/views/kfs/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Edit KFS',
          active: true,
        },
      ],
      permissions: 'edit/kfs-management',
    },
  },
  {
    path: '/kfs-management/:id',
    name: 'detail-kfs',
    component: () => import('@/views/kfs/detail/index.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          active: true,
        },
      ],
      permissions: [
        'detail/kfs-management',
        'view/employee/kfs-management',
        'add/employee/kfs-management',
        'filter/employee/kfs-management',
        'detail/employee/kfs-management',
        'delete/employee/kfs-management',
        'export/employee/kfs-management',
        'edit/employee/kfs-management',
        'add/treatment/kfs-management',
        'view/treatment/kfs-management',
        'detail/treatment/kfs-management',
        'filter/treatment/kfs-management',
        'edit/treatment/kfs-management',
        'delete/treatment/kfs-management',
        'export/treatment/kfs-management',
        'view/product/kfs-management',
        'add/product/kfs-management',
        'filter/product/kfs-management',
        'detail/product/kfs-management',
        'edit/product/kfs-management',
        'delete/product/kfs-management',
        'export/product/kfs-management',
        'view/promo/kfs-management',
        'filter/promo/kfs-management',
        'detail/promo/kfs-management',
        'add/promo/kfs-management',
        'edit/promo/kfs-management',
        'delete/promo/kfs-management',
        'export/promo/kfs-management',
        'view/poin/kfs-management',
        'add/poin/kfs-management',
        'filter/poin/kfs-management',
        'detail/poin/kfs-management',
        'edit/poin/kfs-management',
        'export/poin/kfs-management',
      ],
    },
    props: true,
  },
  {
    path: '/kfs-management/:id/promo/add',
    name: 'detail-kfs-promo-add',
    component: () => import('@/views/kfs/detail/promo/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS - Tambah Promo',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          goBack: true,
        },
        {
          text: 'Tambah Promo',
          active: true,
        },
      ],
      permissions: [
        'detail/promo/kfs-management',
        'add/promo/kfs-management',
        'edit/promo/kfs-management',
      ],
    },
  },
  {
    path: '/kfs-management/:id/customer/add',
    name: 'detail-kfs-customer-add',
    component: () => import('@/views/customer-management/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS - Tambah Customer',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          goBack: true,
        },
        {
          text: 'Tambah Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kfs-management/:id/promo/edit/:idPromo',
    name: 'detail-kfs-promo-edit',
    component: () => import('@/views/kfs/detail/promo/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS - Edit Promo',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          goBack: true,
        },
        {
          text: 'Edit Promo',
          active: true,
        },
      ],
      permissions: [
        'detail/promo/kfs-management',
        'add/promo/kfs-management',
        'edit/promo/kfs-management',
      ],
    },
  },
  {
    path: '/kfs-management/:id/customer/edit/:idCustomer',
    name: 'detail-kfs-customer-edit',
    component: () => import('@/views/customer-management/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS - Edit Customer',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          goBack: true,
        },
        {
          text: 'Edit Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kfs-management/:id/customer/:idCustomer',
    name: 'detail-kfs-customer',
    component: () => import('@/views/customer-management/form.vue'),
    meta: {
      link: 'kfs-management',
      pageTitle: 'KFS - Detail Customer',
      breadcrumb: [
        {
          text: 'KFS Management',
          to: '/kfs-management',
        },
        {
          text: 'Detail KFS',
          goBack: true,
        },
        {
          text: 'Detail Customer',
          active: true,
        },
      ],
    },
  },
]
