export default [
  // List
  {
    path: '/owner-management',
    name: 'owner-management',
    component: () => import('@/views/owner-management/index.vue'),
    meta: {
      link: 'owner-management',
      pageTitle: 'Owner Management',
      breadcrumb: [
        {
          text: 'Owner Management',
          active: true,
        },
      ],
      permissions: 'view/owner',
    },
  },

  // Add
  {
    path: '/owner-management/add',
    name: 'add-owner',
    component: () => import('@/views/owner-management/form.vue'),
    meta: {
      link: 'owner-management',
      pageTitle: 'Owner Management',
      breadcrumb: [
        {
          text: 'Owner Management',
          to: '/owner-management',
        },
        {
          text: 'Add owner',
          active: true,
        },
      ],
      permissions: 'add/owner',
    },
  },

  // Edit
  {
    path: '/owner-management/edit/:id',
    name: 'edit-owner',
    component: () => import('@/views/owner-management/form.vue'),
    meta: {
      link: 'owner-management',
      pageTitle: 'Owner Management',
      breadcrumb: [
        {
          text: 'Owner Management',
          to: '/owner-management',
        },
        {
          text: 'Edit owner',
          active: true,
        },
      ],
      permissions: 'edit/owner',
    },
  },

  // Detail
  {
    path: '/owner-management/:id',
    name: 'detail-owner',
    component: () => import('@/views/owner-management/form.vue'),
    meta: {
      link: 'owner-management',
      pageTitle: 'Owner Management',
      breadcrumb: [
        {
          text: 'Owner Management',
          to: '/owner-management',
        },
        {
          text: 'Detail owner',
          active: true,
        },
      ],
      permissions: 'detail/owner',
    },
  },
]
