export default [
  {
    path: '/invoice',
    name: 'invoice',
    component: () => import('@/views/invoice/index.vue'),
    meta: {
      link: 'invoice',
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'Invoice',
          active: true,
        },
      ],
      permissions: 'view/invoice',
    },
  },
  {
    path: '/invoice/:id',
    name: 'detail-invoice',
    component: () => import('@/views/invoice/form.vue'),
    meta: {
      link: 'invoice',
      pageTitle: 'Change Invoice',
      breadcrumb: [
        {
          text: 'Invoice',
          to: '/invoice',
        },
        {
          text: 'Detail Invoice',
          active: true,
        },
      ],
      permissions: [
        'approve/invoice',
        'reject/invoice',
        'detail/invoice',
      ],
    },
  },
]
